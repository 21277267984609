$(() => {
  window.Geenie.ProjectsInvestment = {
    get_promise() {
      const projectId = $('#project_id').val();
      return $.ajax({
        type: 'get',
        dataType: 'json',
        url: `/api/projects/${projectId}/investment-info`,
        cache: false,
      }).done(data => {
        const $collectedMoney = $('[data-investment-info-collected-money]');
        $collectedMoney.text(`${data.collected_money}円`);
        const $percentStyle = $('[data-investment-info-percent-style]');
        $percentStyle.attr(
          'style',
          `width:${data.percent}%; background-size:${data.percent ? Math.max(100, (100 / data.percent) * 100) : 100}%;`,
        );
        const $percent = $('[data-investment-info-percent]');
        $percent.text(`${data.percent}%`);
        const $supporter = $('[data-investment-info-collected-supporter]');
        $supporter.text(`${data.collected_supporter}人`);
        $.each(data.projects_returns, function () {
          const $return = $(`#return${this.id}`);

          if (!this.can_purchase) {
            $return.find('.returnDetails').remove();
          } else if (!this.is_nolimit) {
            const $remainDetail = $return.find(
              '[data-investment-info-return-remain-detail]',
            );
            $remainDetail.text(this.remain);
          }
          const $supportedAmount = $return.find(
            '[data-investment-info-return-supported-amount]',
          );
          $supportedAmount.text(`${this.supported_amount}人`);
          if (!this.can_purchase) {
            $return
              .find(
                '[data-investment-info-return-link-to-select-btn="end-of-reception"]',
              )
              .show();
          } else if (this.is_soldout) {
            $return
              .find(
                '[data-investment-info-return-link-to-select-btn="sold-out"]',
              )
              .show();
          } else {
            $return
              .find(
                '[data-investment-info-return-link-to-select-btn="not-sold-out"]',
              )
              .show();
          }
        });
      });
    },
  };
});
